import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Raven from 'raven-js';
import ReactGA from 'react-ga';

import localforage from 'localforage';
import { GetRoutes, GetMenu, GetInfoName, GetUserRoleName } from './routes';

import { Menu, Image, Transition, Dropdown, Loader, Header, Icon, Label, Modal, Button } from 'semantic-ui-react';
import Login from './pages/Login';

import IDs from 'unid-core/lib/utils/IDs';
import { loadTheatersData } from 'unid-core/lib/Theaters';

import './App.css';
export default class App extends Component {
  constructor(props, context) {
    super(props, context);

    this.connectionWasLost = false;
    this.hash = '';

    this.state = {
      currentPath: '/',
      notificationDropDownIsOpen: false,
      userMenuDropDownIsOpen: false,
      isMobile: false,
      isSidebarVisible: true,
      loadingTheaters: true,
      needsReload: false,
      animationFlash: false,
      reloadModalShow: false
    };
  }

  updateContentMargins() {
    // Procura pela div do conteúdo antes de continuar
    let e = document.getElementsByClassName('App-Content');
    if (!e[0]) return;

    // Define o tamanho do conteúdo
    if (this.state.isMobile) e[0].style['margin'] = '0 0 0 0';
    else {
      if (this.state.isSidebarVisible)  e[0].style['margin'] = '0 0 0 230px';
      if (!this.state.isSidebarVisible) e[0].style['margin'] = '0 0 0 0';
    }
  }

  checkWindow() {
    if (document.body.clientWidth < 975 && !this.state.isMobile)
      this.setState({ isMobile: true, isSidebarVisible: false });

    if (document.body.clientWidth >= 975 && this.state.isMobile)
      this.setState({ isMobile: false, isSidebarVisible: true });
  }

  async componentDidMount() {
    const token = await localforage.getItem('token');
    window.addEventListener('resize', this.checkWindow.bind(this));

    if (!token) this.setState({ userData: null });
    else {
      try {
        this.onLogin((await window.cs_get('/user/token-login')).data);
      } catch (err) {
        await localforage.removeItem('user_id');
        await localforage.removeItem('token');
        this.onLogin(null);
      }
    }

    if (process.env.NODE_ENV === 'production') {
      let toastId = undefined;
      setInterval(() => this.setState({ animationFlash: !this.state.animationFlash }), 8000);

      setInterval(async () => {
        if (toastId && !toast.isActive(toastId))
          toastId = undefined;

        try {
          const res = await window.cs_get('/ping');

          if (!this.hash) {
            this.hash = res.data.hash;
            Raven.config('https://dd7781b4b7874591949f1e10e74c3fe6@sentry.io/1199843', { release: this.hash }).install();
          }

          if (this.hash !== res.data.hash) this.setState({ needsReload: true });

          if (this.connectionWasLost) {
            this.connectionWasLost = false;

            const content = (
              <div>
                <Header as='h5' style={{margin:'6px 0 6px 0',color:'#FFF'}}><Icon name='info circle' />Conexão restabelecida</Header>
                <p>A conexão com o servidor foi restabelecida. Agora você pode continuar usando o sistema normalmente.</p>
              </div>
            );

            if (!toastId) toastId = toast.success(content, { autoClose: false, closeButton: true });
            else toast.update(toastId, { type: toast.TYPE.SUCCESS, render: content, closeButton: true });
          }
        } catch (err) {
          this.connectionWasLost = true;

          const content = (
            <div>
              <Header as='h5' style={{margin:'6px 0 6px 0',color:'#FFF'}}><Icon name='warning sign' />Problema na conexão</Header>
              <p>A conexão com o servidor foi perdida. Para evitar eventuais percas de dados na tela em que você está, por favor aguarde enquanto a conexão é restabelecida.</p>
            </div>
          );

          if (!toastId) toastId = toast.error(content, { autoClose: false, closeButton: false });
          else toast.update(toastId, { type: toast.TYPE.ERROR, render: content, closeButton: false });
        }
      }, 5000);
    }
  }

  async onLogin(userData) {
    if (userData) {
      await localforage.setItem('user_id', userData.id);
      await localforage.setItem('token', userData.token);

      if (process.env.NODE_ENV === 'production') {
        Raven.setUserContext({
          id: userData.id,
          email: userData.email,
          name: userData.name,
          refs: userData.refs,
          role: userData.role
        });

        ReactGA.set({
          userId: userData.id
        });

        if (userData.role !== IDs.Admin && userData.role !== IDs.Unid) {
          (function(h,o,t,j,a,r) {
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:867423,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        }
      }

      loadTheatersData(() => {
        this.setState({ loadingTheaters: false });
      });
    }

    this.setState({ userData });
  }

  componentDidUpdate() {
    this.checkWindow();
    this.updateContentMargins();
  }

  render() {
    /*return (
      <div style={{maxWidth:960,margin:"0 auto"}}>
        <Image src='/imgs/logo.png' style={{maxWidth:280,margin:"0 auto",padding:"15vh 0 60px"}} />
        <Message style={{padding:"24px 24px 20px"}} info icon="info circle" header="Sistema em manutenção" content="Estamos efetuando uma manutenção em nossos servidores. Por favor aguarde alguns minutos e tente novamente." />
      </div>
    );*/

    // Aguardando dados do usuário e cinemas
    if (this.state.userData === undefined || (this.state.loadingTheaters && this.state.userData !== null))
      return <Loader active={true} />

    // Usuário está ou não logado
    if (this.state.userData !== null) {
      const routes = GetRoutes(this.state.userData, this);
      const menu = GetMenu(this.state.userData, this);

      const UserMenu = (
        <div className='UserMenu' style={{backgroundColor:this.state.userMenuDropDownIsOpen?'#CCC':null}}>
          <div>
            <Image src='/imgs/profile/admin.png' style={{margin:13,width:40,border:'2px solid #444',borderRadius:20}} />
          </div>
          <div style={{margin:'16px 28px 0 6px',color:'#444'}}>
            <p style={{margin:0,fontSize:14,fontWeight:600}}>{this.state.userData.name}</p>
            <p style={{margin:0,fontSize:11}}>{ GetUserRoleName() }</p>
          </div>
        </div>
      );

      let UserMenuDropDown = [
        { key: 'account', text: 'Conta', icon: 'user', disabled: true },
        { key: 'settings', text: 'Configurações', icon: 'settings', disabled: true },
        { key: 'logout', text: 'Sair', icon: 'shutdown', onClick: async () => {
          try {
            await window.cs_get('/user/logout');
            this.setState({ userData: null });
            localforage.removeItem('token');
            window.location.reload(true);
          } catch (err) {
            console.error(err);
            alert('Falha ao efetuar logout');
            window.location.reload(true);
          }
        }}
      ];

      if (this.state.userData.oldId) {
        UserMenuDropDown.splice(UserMenuDropDown.length - 1, 0, {
          key: 'back', text: 'Voltar', icon: 'sign out', onClick: async () => {
            try {
              await window.cs_delete('/user/as');
              await localforage.setItem('user_id', this.state.userData.oldId);
              window.location = '/';
            } catch (err) {
              console.error(err);
              window.location.reload(true);
            }
          }
        });
      }

      const NotifationMenu = (
        <div className='UserMenu' style={{backgroundColor:this.state.notificationDropDownIsOpen?'#CCC':null}}>
          <div style={{margin:'23px 15px 19px'}}>
            <Icon name='bell outline' style={{fontSize:'1.6em',color:'#444'}}>
              { false === true && <Label style={{left:'65%',top:'50%',fontSize:'0.4rem'}} color='red' floating circular /> }
            </Icon>
          </div>
        </div>
      );

      const NotifationMenuDropDown = [
        { key: 'none', text: 'Nenhuma notificação para exibir', disabled: true }
      ];

      return (
        <BrowserRouter>
          <div>
            <ToastContainer />

			<div className='App-TopBar'>
				{ !this.state.isMobile && <Image src='/imgs/logo_small.png'/> }

				<span className='icone-barras' onClick={()=>this.setState({ isSidebarVisible: !this.state.isSidebarVisible })}></span>

				<Dropdown onOpen={()=>this.setState({ userMenuDropDownIsOpen: true })} onClose={()=>this.setState({ userMenuDropDownIsOpen: false })} trigger={UserMenu} options={UserMenuDropDown} icon={null} />
				<Dropdown direction='left' onOpen={()=>this.setState({ notificationDropDownIsOpen: true })} onClose={()=>this.setState({ notificationDropDownIsOpen: false })} trigger={NotifationMenu} options={NotifationMenuDropDown} icon={null} />
				
				{ this.state.needsReload &&
					<Transition animation='flash' duration={1000} visible={this.state.animationFlash}>
					<div className='reload' onClick={() => this.setState({ reloadModalShow: true })}>Atualização disponível</div>
					</Transition>
				}
			</div>

			<Transition visible={this.state.isSidebarVisible} animation='slide right' duration={300}>
				<Menu borderless vertical inverted fixed='left' size='large' className='App-Menu'>

					<Image centered shape='circular' src='/imgs/profile/admin.png' style={{width:'35%',marginTop:42}} />

					<div style={{color:'#FFF',textAlign:'center',paddingBottom:24}}>
						<p style={{margin:'10px 0 2px',fontWeight:600,fontSize:16}}>{this.state.userData.name}</p>

						{ GetInfoName().map((e, i) => {
							return <p key={i} style={{fontSize:12,margin:'0 12px',textTransform:'capitalize',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{ e }</p>
						})}
					</div>

					{ menu }
				</Menu>
			</Transition>

            <div className='App-Content'>
              { routes }
            </div>

            <Modal closeOnDimmerClick={false} size={'tiny'} open={this.state.reloadModalShow}>
              <Modal.Header>
                Atualização disponível
              </Modal.Header>
              <Modal.Content>
                <p>Você tem certeza que deseja recarregar a página?</p>
              </Modal.Content>
              <Modal.Actions>
                <Button negative content='Não' onClick={()=>this.setState({ reloadModalShow: false })} />
                <Button positive icon='checkmark' labelPosition='right' content='Sim' onClick={() => window.location.reload(true) }/>
              </Modal.Actions>
            </Modal>
          </div>
        </BrowserRouter>
      );
    } else {
      return <Login onLogin={this.onLogin.bind(this)} />
    }
  }
}
