import React, { Component } from 'react';
import { Dimmer, Loader, List, Button, Label, Modal, Message, Form } from 'semantic-ui-react';

import localforage from 'localforage';

import IDs from 'unid-core/lib/utils/IDs';

import DropzoneComponent from 'react-dropzone-component';

import './FileManager.css';

// 
import { MS_API_PATH } from '../links';

export default class FileManager extends Component {
  token = '';
  user_id = '';

  state = {
    remove: null,
    ref: null,
    mediaRef: '',
    medias: undefined,
    uploading: false,
    viwer: null
  }

  async listMedias() {
    try {
      const res = await window.ms_get(`/medias/order/${this.props.order.id}`);
      this.setState({ medias: res.data });
      this.props.onMediaListUpdated(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  async removeFile() {
    const { id } = this.state.remove;
    this.setState({ remove: null });

    try {
      await window.ms_delete(`/medias/${id}`);
      this.listMedias();
    } catch (err) {
      console.error(err);
    } 
  }

  async saveRef() {
    const { id } = this.state.ref;
    this.setState({ ref: null });

    try {
      await window.ms_post(`/medias/${id}/ref`, { ref: this.state.mediaRef });
      this.listMedias();
    } catch (err) {
      console.error(err);
    }
  }

  async componentDidMount() {
    this.token = await localforage.getItem('token');
    this.user_id = await localforage.getItem('user_id');
    this.i = setInterval(() => this.listMedias(), 10000);
    this.listMedias();
  }

  componentWillUnmount() {
    clearInterval(this.i);
  }

  render() {
    const djsConfig = {
      timeout: 0,
      maxFilesize: 2000,
      addRemoveLinks : true,
      autoProcessQueue: true,
      dictDefaultMessage: '<h1>Clique ou arraste o arquivo aqui para fazer upload. Tamanho máximo: <b>2 GB</b></h1><p>Não é possível fazer o envio de arquivos compactados (rar, 7z, zip, etc)</p>',
      dictResponseError: 'Erro ao tentar fazer upload',	
      dictFallbackText: 'Envie os arquivos abaixo.',
      dictFileTooBig: 'O arquivo é muito grande. Tamanho máximo: 2 GB.',
      dictInvalidFileType: 'Você nao pode enviar arquivos desse tipo.',
      dictCancelUpload: 'Cancelar upload',
      dictCancelUploadConfirmation: 'Você tem certeza que deseja cancelar esse upload?',
      dictRemoveFile: 'Remover da lista',
      dictRemoveFileConfirmation: null,
      dictMaxFilesExceeded: 'Você não pode enviar mais arquivos.'
    };

    const dropzoneConfig = {
      postUrl: `${MS_API_PATH}/medias`
    };

    const eventHandlers = {
      sending: (file, xhr, formData) => {
        xhr.setRequestHeader('x-userid', this.user_id);
        xhr.setRequestHeader('x-token', this.token);
        formData.append('order_id', this.props.order.id);
      },
      complete: (file) => {
        if (file.status === 'success') {
          this.listMedias();
          setTimeout(() => {
            this.refs.dz.dropzone.removeFile(file);
          }, 1500);
        }
      },
      totaluploadprogress: (progress) => {
        if (progress < 100) this.setState({ uploading: true });
        else this.setState({ uploading: false });
      }
    };

    return (
      <div style={{minHeight:140}} className='FileManager'>
        <Dimmer inverted active={!this.state.medias} ><Loader /></Dimmer>
        <DropzoneComponent ref='dz' config={dropzoneConfig} eventHandlers={eventHandlers} djsConfig={djsConfig} />
        { this.state.medias && this.state.medias.length === 0 && <Message warning header='Nenhum arquivo anexado nesse PI' content='Para veicular mídias, primeiro faça upload de um arquivo de mídia compatível.' icon='warning' /> }
        { this.state.medias && this.state.medias.length > 0 &&
          <List divided relaxed>
            { this.state.medias.map((e, i) => {
              if (this.props.userData.role !== IDs.Admin && this.props.userData.role !== IDs.Unid)
                e.ref = null;

              return (
                <List.Item key={i}>
                  { e.status === 1 &&
                    <List.Content floated='right'>
                      { e.compatible_with.indexOf(IDs.Preshow.toString()) !== -1 && (this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid) && <Button color='olive' size='mini' content='DCP' style={{paddingLeft:8,paddingRight:8}} onClick={() => this.setState({ ref: e, mediaRef: e.ref||'' })} /> }
                      <Button primary size='mini' icon='eye' onClick={() => this.setState({ viwer: e }) } />
                      <a href={`https://media.unidcinemidia.com.br/medias/${this.props.order.id}/${e.id}/download`} target='_blank' rel='noopener noreferrer'><Button primary size='mini' icon='download' /></a>
                      <Button negative size='mini' icon='trash' onClick={()=>this.setState({ remove: e })} />
                    </List.Content>
                  }

                  <List.Icon name={e.video?'file video outline':'file image outline'} size='large' verticalAlign='middle' style={{width:30}} />
                  <List.Content>
                    <List.Header>{e.name} {e.ref?<span style={{color:'#999',fontSize:10}}>({e.ref})</span>:null}</List.Header>
                    <List.Description>
                      { e.status === 1 && e.approved === -1 && <Label color='red' size='tiny' content='Mídia não aceita' /> }
                      { e.status === 1 && e.approved === 0 && <Label color='orange' size='tiny' content='Aguardando revisão' /> }
                      { e.status === 0 && <Label color='purple' size='tiny' content='Processando...' /> }
                      { e.compatible_with.split(',').map((e, i) => {
                        if (e === IDs.Preshow.toString()) return <Label key={i} color='blue' size='tiny' content='Mídia em Tela' />
                        if (e === IDs.Videowall.toString()) return <Label key={i} color='blue' size='tiny' content='Videowall' />
                        if (e === IDs.Bomboniere.toString()) return <Label key={i} color='blue' size='tiny' content='Bomboniere' />
                        if (e === IDs.Foyer.toString()) return <Label key={i} color='blue' size='tiny' content='Foyer' />
                        return null;
                      })}
                      <Label size='tiny' content={`${e.width}x${e.height}`} />
                      { e.video === 1 && <Label size='tiny' content={`${Math.round(e.length)}s`} /> }
                    </List.Description>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        }

        <Modal size={'tiny'} open={!!this.state.remove}>
          <Modal.Header>
            Removendo arquivo
          </Modal.Header>
          <Modal.Content>
            <p>Você tem certeza que deseja remover {this.state.remove && this.state.remove.name}?</p>            
          </Modal.Content>
          <Modal.Actions>
            <Button negative content='Cancelar' onClick={()=>this.setState({ remove: null})} />
            <Button positive icon='checkmark' labelPosition='right' content='Sim, remover' onClick={this.removeFile.bind(this)}/>
          </Modal.Actions>
        </Modal>

        <Modal size={'tiny'} open={!!this.state.ref}>
          <Modal.Header>
            Atualizar definição de DCP
          </Modal.Header>
          <Modal.Content>
            <Form>    
              <Form.Input autoComplete='off' name='mediaRef' label='Nome do DCP' value={this.state.mediaRef} onChange={(e, { name, value }) => this.setState({ [name]: value })} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative content='Cancelar' onClick={()=>this.setState({ ref: null })} />
            <Button positive icon='checkmark' labelPosition='right' content='Salvar' onClick={this.saveRef.bind(this)}/>
          </Modal.Actions>
        </Modal>

        <Modal size='small' open={!!this.state.viwer} onClose={() => this.setState({ viwer: null })}>
          { this.state.viwer && this.state.viwer.video === 1 &&
            <video style={{width:'100%'}} autoPlay controls src={`https://media.unidcinemidia.com.br/medias/${this.props.order.id}/${this.state.viwer.id}`} />
          }
          { this.state.viwer && this.state.viwer.video === 0 &&
            <img style={{width:'100%'}} alt='Imagem' src={`https://media.unidcinemidia.com.br/medias/${this.props.order.id}/${this.state.viwer.id}`} />
          }
        </Modal>
      </div>
    );
  }
};