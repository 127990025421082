import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { Grid, Dimmer, Loader, Segment, Popup, Header, Icon, Button, Modal, Checkbox, Message, Table, Form, Search } from 'semantic-ui-react';
//import SignaturePad from 'react-signature-pad';
import ReactToPrint from 'react-to-print';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import LocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import ContentHeader from '../components/ContentHeader';
import TitledBlock from '../components/TitledBlock';
import FileManager from '../components/FileManager';
import OrderQuotaEditor from '../components/OrderQuotaEditor';

import { parseProductsFromOrder } from 'unid-core/lib/utils/products';
import { formatCurrency, formatNumber } from 'unid-core/lib/utils/formatter';
import { getNetworkById, getTheaterById } from 'unid-core/lib/Theaters';
import IDs from 'unid-core/lib/utils/IDs';

import './Order.css';

const nfseTypes = [
	{ text: '10.08 - Agenciamento de publicidade e propaganda, ...', value: '1008' },
	{ text: '12.08 - Feiras, exposições, congressos e congêneres', value: '1208' },
	{ text: '12.13 - Produção, mediante ou sem encomenda prévia, de eventos, ...', value: '1213' }
];

class Order extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			advertisers: [],
			searchingAdvertiser: false,
			searchValue: '',
			searchResults: [],

			termsAccepted: false,
			handSignature: false,
			showTermsModal: false,
			showNFSeModal: false,
			showBoletoModal: false,

			order: null,
			nfses: null,
			tickets: null,
			advertiser: null,
			agency: null,

			nfseLoading: false,
			nfseEntity: null,
			nfseInstallment: 0,
			nfseType: '1008',
			nfseValue: 0,
			nfseCut: 0,
			nfseNoCut: false,
			nfseText: '',
			nfseRemove: undefined,
			nfseCutError: undefined,

			boletoLoading: false,
			boletoEntity: null,
			boletoInstallment: 0,
			boletoValue: 0,
			boletoDate: moment().toDate(),
			boletoDescription: '',
			boletoRemove: undefined,

			taxes: { municipalTax: '', federalTax: '' }
		};

		const identifier = props.match.params.id;
		if (!identifier) return;
	}

	async updateOrder() {
		try {
			const vars = await window.cs_get('/vars');

			let municipalTax = '';
			let federalTax = '';
			
			for (const row of vars.data) {
			if (row.name === 'municipal_nfe_tax') municipalTax = row.value;
			if (row.name === 'federal_nfe_tax') federalTax = row.value;
			}

			this.setState({ taxes: { municipalTax, federalTax }});
		} catch {}

		try {
			const order = (await window.cs_get(`/orders/${this.props.match.params.id}`, { full: true })).data;
			const advertiser = (await window.cs_get(`/entities/${order.advertiser_id}`)).data;
			advertiser['contacts'] = JSON.parse(advertiser['contacts']);

			let agency = null;

			if (advertiser.rel_id) {
			agency = (await window.cs_get(`/entities/${advertiser.rel_id}`)).data;
			agency['contacts'] = JSON.parse(agency['contacts']);
			}

			await this.updateNfse();
			await this.updateTickets();

			this.setState({ order, advertiser, agency });
		} catch (err) {
			alert('Não foi possível abrir o pedido com o ID ' + this.props.match.params.id);
			console.error(err);
		}
	}

	async updateNfse() {
		try {
			const nfses = (await window.cs_get(`/nfse/${this.props.match.params.id}`)).data;
			this.setState({ nfses });
		} catch (err) {
			console.error(err);
		}
	}

	async updateTickets() {
		try {
			const tickets = (await window.cs_get(`/tickets/${this.props.match.params.id}`)).data;
			this.setState({ tickets });
		} catch (err) {
			console.error(err);
		}
	}

	async updateCheckingReport() {
		try {
			const report = (await window.cs_get(`/programming/checking/${this.props.match.params.id}/report`)).data;
			this.setState({ checkingReport: report });
		} catch (err) {
			console.error(err);
		}
	}

	async sendSignature() {
	//const signature = this.refs.signature.isEmpty() || this.state.handSignature ? 'byhand' : this.refs.signature.toDataURL();
	//this.refs.signature.clear();

	this.setState({ termsAccepted: false, showTermsModal: false, order: null });
	await window.cs_post(`/orders/${this.state.order.id}/signature`, { signature: "byhand" });
	this.updateOrder();
	}

		updateNFSeText() {
		const order = this.state.order;
		const installment = order.installments_details[this.state.nfseInstallment];
		const cuts = this.getCutsInfo(true)[this.state.nfseInstallment];

		let value = this.state.nfseValue;

		if (this.state.nfseNoCut) {
			value -= cuts.agencyCut;
			for (let cut of cuts.networksCuts)
			if (cut.cut) value -= cut.cut;
		}

		let placeInfo = '';
		let paymentInfo = `Forma de pagamento: ${order.installments} parcelas no valor de ${formatCurrency(order.total / order.installments)} cada, com vencimento em ${moment(order.installments_details[0].date).format('DD/MM/YYYY')} a ${moment(order.installments_details[order.installments-1].date).format('DD/MM/YYYY')}.`;
		let installmentInfo = `NOTA FISCAL REFERENTE${this.state.nfseNoCut?' AO PERCENTUAL DA UNID\n':' À '}${this.state.nfseInstallment+1}ª PARCELA (${moment(installment.date).format('DD/MM/YYYY')}) = ${formatCurrency(value)}`;

		const products = parseProductsFromOrder(order);

		for (let product of products) {
			for (let theater of product.theaters) {
			let shopping = theater.additional_data['shopping']||'';
			placeInfo += `${placeInfo?', ':''}${shopping?shopping+' - ':''}${theater.city}/${theater.uf}`;
			}
		}

		let cutsInfo = '';
		if (cuts.agencyCut)
			cutsInfo += `Comissão Agência: ${formatCurrency(cuts.agencyCut)}\n`;
		for (let cut of cuts.networksCuts)
			if (cut.cut && !this.state.nfseNoCut)
			cutsInfo += `Repasse para ${getNetworkById(cut.network) ? getNetworkById(cut.network).name : getTheaterById(cut.network).name}: ${formatCurrency(cut.cut)}\n`;

		let taxes = '18%';

		const {municipalTax, federalTax} = this.state.taxes;

		if (municipalTax && federalTax) {
			const municipalTaxValue = value * (parseFloat(municipalTax) / 100);
			const federalTaxValue = value * (parseFloat(federalTax) / 100);
			taxes = `${formatCurrency(federalTaxValue)} federal e ${formatCurrency(municipalTaxValue)} municipal`;
		}

		this.setState({
			nfseText: `NOTA FISCAL REFERENTE À COMERCIALIZAÇÃO DE ESPAÇO PUBLICITÁRIO\n\nLocal: ${placeInfo}\n\nID ${order.id}\nTOTAL DO INVESTIMENTO: ${formatCurrency(order.total)}\n\n${paymentInfo}\n\n${cutsInfo?`${cutsInfo}\n`:''}${installmentInfo}\n\nDe acordo com a regulamentação sobre a intermediação e prestação de serviços e acessórios, a base de cálculo do ISS é o preço do serviço, como tal considerada a receita bruta a ele correspondente, sem nenhuma dedução, excetuados os descontos ou abatimentos concedidos independentemente de qualquer condição, portanto, a Nota Fiscal emitida será no valor total com a dedução do percentual de repasse para o Cinema e/ou Agência de Publicidade.\n\nTributos aproximado: ${taxes}\n*** Fonte IBPT`
		});
	}

	newNFSe(installment) {
	const order = this.state.order;
	const advertiser = this.state.advertiser;
	const cutsInfo = this.getCutsInfo();

	this.setState({
		showNFSeModal: true,
		nfseLoading: false,
		nfseInstallment: installment,
		nfseType: '1008',
		nfseValue: order.installments_details[installment].price,
		nfseCut: cutsInfo[installment].networksCut,
		nfseNoCut: false,
		nfseEntity: advertiser.id,
		searchValue: advertiser.name
	}, () => {
		this.updateNFSeText();
		this.updateNFSeMinMax();
	});
	}

	async addNFSe() {
	this.setState({ nfseLoading: true });
	const cuts = this.getCutsInfo(true)[this.state.nfseInstallment];

	try {
		await window.cs_post('/nfse', {
		order: this.state.order.id,
		installment: this.state.nfseInstallment,
		entity: this.state.nfseEntity,
		value: this.state.nfseValue,
		noCut: this.state.nfseNoCut,
		agencyCut: cuts.agencyCut,
		networksCut: this.state.nfseCut,
		text: this.state.nfseText,
		type: this.state.nfseType
		});

		this.setState({ showNFSeModal: false, nfseLoading: false });
		this.updateNfse();
	} catch (err) {
		console.error(err);
		this.setState({ nfseLoading: false });
	}
	}

	newTicket(installment) {
	const order = this.state.order;
	const advertiser = this.state.advertiser;

	this.setState({
		showBoletoModal: true,
		boletoLoading: false,
		boletoInstallment: installment,
		boletoValue: order.installments_details[installment].price,
		boletoDate: order.installments_details[installment].date,
		boletoEntity: advertiser.id,
		searchValue: advertiser.name,
		boletoDescription: `${installment+1}ª PARCELA REFERENTE AO TERMO ID ${order.id}`
	});
	}

	async addBoleto() {
	this.setState({ boletoLoading: true });

	try {
		await window.cs_post('/tickets', {
		order: this.state.order.id,
		installment: this.state.boletoInstallment,
		entity: this.state.boletoEntity,
		value: this.state.boletoValue,
		date: this.state.boletoDate,
		description: this.state.boletoDescription
		});

		this.setState({ showBoletoModal: false, boletoLoading: false });
		this.updateTickets();
	} catch (err) {
		console.error(err);
		this.setState({ boletoLoading: false });
	}
	}

	async removeNFSe() {
	const id = this.state.nfseRemove;
	this.setState({ nfseRemove: undefined });

	try {
		await window.cs_delete(`/nfse/${id}`);
		this.updateNfse();
	} catch (err) {
		console.error(err);
		this.updateNfse();
	}
	}

	async removeBoleto() {
	const id = this.state.boletoRemove;
	this.setState({ boletoRemove: undefined });

	try {
		await window.cs_delete(`/tickets/${id}`);
		this.updateTickets();
	} catch (err) {
		console.error(err);
		this.updateTickets();
	}
	}

	async setOrderActive(status) {
	try {
		await window.cs_post(`/orders/${this.state.order.id}/activate`, { active: status });
		this.setState({ order: { ...this.state.order, active: status } });
	} catch(err) {
		console.error(err);
		alert('Ocorreu um erro inesperado =(');
	}
	}

	async setAutoNfeActive(status) {
	try {
		await window.cs_post(`/orders/${this.state.order.id}/activate-auto-nfe`, { active: status });
		this.setState({ order: { ...this.state.order, auto_nfe: status } });
	} catch(err) {
		console.error(err);
		alert('Ocorreu um erro inesperado =(');
	}
	}

	firstContact(type, contacts) {
	let ret = null;

	for (let contact of contacts) {
		if (contact.type === type) {
		if (contact.tags.indexOf('Contato') !== -1) return contact.value;
		else ret = contact.value;
		}
	}

	return ret;
	}

	getCutsInfo(simulate = false) {
	const order = this.state.order;
	let total = order.total;

	let networksTotalCut = 0;
	for (let cut of order.cuts) {
		if (cut.type === IDs.Cuts.Network)
		networksTotalCut += cut.cut;
	}

	let agencyTotalCut = 0;
	for (let cut of order.cuts) {
		if (cut.type === IDs.Cuts.Agency)
		agencyTotalCut += cut.cut;
	}

	// Subtrai os valores pagos
	let networksTotalCutPaid = 0;
	for (let payment of this.state.nfses) {
		networksTotalCutPaid += payment.networks_cut;
		networksTotalCut -= payment.networks_cut;
		agencyTotalCut -= payment.agency_cut;
		total -= payment.value;
	}

	// Para cada parcela que já possui nota, subtrai a diferença do valor gerado e do total para calcular os repasses subsequintes corretamente
	for (let i = 0; i < order.installments_details.length; i++) {
		// Assume que a parcela não possui NF
		let generated = undefined;

		for (let payment of this.state.nfses) {
		if (payment.installment_idx !== i) continue;
		if (generated === undefined) generated = 0;
		// Soma o valor da nota
		generated += payment.value;
		}

		// Se possui algo gerado, subtrai a diferença
		if (generated) total -= order.installments_details[i].price - generated;
	}

	let networksCutRatio =  networksTotalCut / total;
	let agencyCutRatio = agencyTotalCut / total;
	let ret = [];

	for (let i = 0; i < order.installments_details.length; i++) {
		const installment = order.installments_details[i];

		// Soma todos os valores já repassados da parcela
		let networksCutPaid = undefined;
		let agencyCutPaid = undefined;
		let installmentTotal = 0;

		for (let payment of this.state.nfses) {
		if (payment.installment_idx === i) {
			if (networksCutPaid === undefined) networksCutPaid = 0;
			if (agencyCutPaid === undefined) agencyCutPaid = 0;
			networksCutPaid += payment.networks_cut;
			agencyCutPaid += payment.agency_cut;
			installmentTotal += payment.value;
		}
		}

		const agencyCut  =  !(simulate && i === this.state.nfseInstallment) && agencyCutPaid   !== undefined ? agencyCutPaid   : (simulate && i === this.state.nfseInstallment ? this.state.nfseValue : installment.price) * agencyCutRatio;
		const networksCut = !(simulate && i === this.state.nfseInstallment) && networksCutPaid !== undefined ? networksCutPaid : (simulate && i === this.state.nfseInstallment ? this.state.nfseCut : networksCutRatio * installment.price);

		const installmentCutRatio = networksCut / (networksTotalCut + networksTotalCutPaid);

		let networksCuts = [];
		for (let cut of order.cuts) {
		if (cut.type === IDs.Cuts.Network)
		networksCuts.push({ cut: installmentCutRatio * cut.cut, network: cut.entity_id });
		}

		ret.push({ agencyCut, networksCut, networksCuts, finalized: installmentTotal === installment.price });
	}

	return ret;
	}

	updateNFSeMinMax() {
	const order = this.state.order;
	const nfses = this.state.nfses;

	const installmentIdx = this.state.nfseInstallment;
	const cutsInfo = this.getCutsInfo(true)[installmentIdx];

	let maxValue = order.installments_details[installmentIdx].price;
	let maxCutValue = this.state.nfseValue - cutsInfo.agencyCut;

	let available = 0;//-this.state.nfseValue;
	let future = 0;

	for (let installment of order.installments_details) available += installment.price;
	for (let cut of order.cuts)
		if (cut.type === IDs.Cuts.Network) future += cut.cut;
		else available -= cut.cut;

	for (let nfse of nfses) {
		available -= (nfse.value - nfse.agency_cut);
		future -= nfse.networks_cut;

		if (nfse.installment_idx === installmentIdx)
		maxValue -= nfse.value;
	}

	// Calcula o valor mínimo do repasse antes de continuar
	let minCutValue = (available - future) - (this.state.nfseValue - cutsInfo.agencyCut);
	minCutValue = minCutValue < 0 ? Math.abs(minCutValue) : 0;

	if (maxCutValue > future) maxCutValue = future;

	let newState = {};
	if (this.state.nfseValue > maxValue)
		newState['nfseValue'] = maxValue;
	if (this.state.nfseCut < minCutValue || this.state.nfseCut > maxCutValue)
		newState['nfseCutError'] = maxCutValue === 0 ? undefined : (minCutValue === 0 ? `Insira um valor até ${formatCurrency(maxCutValue)}` : `Insira um valor entre ${formatCurrency(minCutValue)} e ${formatCurrency(maxCutValue)}`);
	else
		newState['nfseCutError'] = undefined;
	if (minCutValue === maxCutValue && this.state.nfseCut !== minCutValue)
		newState['nfseCut'] = minCutValue;

	this.setState(newState, () => {
		// Se atualizou o valor da nota ou corte, recalcula os máximos
		if (newState['nfseValue'] || newState['nfseCut'])
		this.updateNFSeMinMax();
		else this.updateNFSeText();
	});
	}

	installmentsList() {
	const order = this.state.order;
	const agency = this.state.agency;
	const cutsInfo = this.getCutsInfo();



	return (
		<Table basic style={{fontSize:12}}>
		<Table.Header>
			<Table.Row>
			<Table.HeaderCell>#</Table.HeaderCell>
			<Table.HeaderCell>Vencimento</Table.HeaderCell>
			<Table.HeaderCell>Valor</Table.HeaderCell>
			{ this.props.userData.role !== IDs.Advertiser && agency && <Table.HeaderCell>Repasse<br/>Agência</Table.HeaderCell> }
			{ this.props.userData.role !== IDs.Advertiser && <Table.HeaderCell>Repasse<br/>Cinema</Table.HeaderCell> }
			<Table.HeaderCell>NFS-e</Table.HeaderCell>
			<Table.HeaderCell>Boleto</Table.HeaderCell>
			</Table.Row>
		</Table.Header>

		<Table.Body>
			{ order.installments_details.map((e, i) => {
			let installmentCuts = (
				<span>
				{ cutsInfo[i].networksCuts.map((e, i) => {
					return <p key={i}><b>{formatCurrency(e.cut)}</b> - {getNetworkById(e.network) ? getNetworkById(e.network).name : getTheaterById(e.network).name}</p>;
				})}
				</span>
			);

			let nfseCount = 0;
			let ticketCount = 0;

			// 2 lugares, caso alteração, pesquise e altere em ambos locais
			function isNewBoleto(number) {
				return number.toString().startsWith('18');
			}

			return (
				<Table.Row key={i}>
				<Table.Cell><b>{i+1}</b></Table.Cell>
				<Table.Cell>{moment(e.date).format('DD/MM/YYYY')}</Table.Cell>
				<Table.Cell>{formatCurrency(e.price)}</Table.Cell>
				{ this.props.userData.role !== IDs.Advertiser && agency && <Table.Cell>{formatCurrency(cutsInfo[i].agencyCut)}</Table.Cell> }
				{ this.props.userData.role !== IDs.Advertiser && <Table.Cell><Popup position='right center' style={{fontSize:10}} content={installmentCuts} trigger={<span style={{color:cutsInfo[i].agencyCut+cutsInfo[i].networksCut>e.price?'#F00':'#000'}}>{formatCurrency(cutsInfo[i].networksCut)}</span>} /></Table.Cell> }
				<Table.Cell>
					{ this.state.nfses.map((e, j) => {
					if (e.installment_idx !== i) return null;
					nfseCount++;

					return (
						<p key={j} style={{margin:nfseCount>1?'5px 0 0 0':'0'}}>
						nº {e.number}
						<a href={`https://maringa.fintel.com.br/ImprimirNfse/${e.number}/21923681000130/${e.verification_code}`} target='_blank' rel='noopener noreferrer'><Button style={{marginLeft:5}} primary icon='eye' /></a>
						{ this.props.userData.role === IDs.Admin && <Button negative icon='trash' onClick={()=>this.setState({nfseRemove:e.number})} /> }
						</p>
					);
					})}
					{ this.props.userData.role === IDs.Admin && !cutsInfo[i].finalized && <Button primary icon='add' content='Nova NFS-e' style={{marginTop:nfseCount>0?5:0}} onClick={()=>this.newNFSe(i)} /> }
				</Table.Cell>
				<Table.Cell>
					{ this.state.tickets.map((e, j) => {
					if (e.installment_idx !== i) return null;
					ticketCount++;

					const isLate = moment().isAfter(moment(e.date).endOf('day'));

					return (
						<p key={j} style={{margin:ticketCount>1?'5px 0 0 0':'0'}}>
						{ !isLate && e.status === 1 && <Popup position='top center' trigger={<Icon name='help circle' color='grey' />} content='Aguardando confirmação bancária' /> }
						{ !isLate && e.status === 2 && <Popup position='top center' trigger={<Icon name='exclamation circle' color='yellow' />} content='A vencer' /> }
						{ e.status === 3 && <Popup position='top center' trigger={<Icon name='check circle' color='olive' />} content='Pago' /> }
						{ isLate && e.status !== 3 && <Popup position='top center' trigger={<Icon name='remove circle' color='red' />} content='Vencido' /> }
						{ e.status === 10 && <Popup position='top center' trigger={<Icon name='exclamation circle' color='yellow' />} content='Aguardando remoção' /> }
						{ e.status === 11 && <Popup position='top center' trigger={<Icon name='help circle' color='blue' />} content='Aguardando remoção bancária' /> }

						<span style={{color:'#999'}}>
							{ isNewBoleto(e.number) && `${e.number.slice(0, 2)}/${e.number.slice(2, 8)}-${e.number.slice(8, 9)}`}
							{ !isNewBoleto(e.number) && `${e.number}`}
						</span>
						<span style={{marginLeft:6,color:'#589945',fontWeight:600}}>{ formatCurrency(e.value) }</span>
						{ e.barcode && <a href={`/tickets/${e.id}/${e.barcode}`} target='_blank' rel='noopener noreferrer'><Button style={{marginLeft:5}} primary icon='eye' /></a> }
						{ !e.barcode && <Button disabled style={{marginLeft:5}} primary icon='eye' /> }
						{ this.props.userData.role === IDs.Admin && e.barcode && <Button negative icon='trash' onClick={()=>this.setState({boletoRemove:e.id})} /> }
						</p>
					);
					})}
					{ this.props.userData.role === IDs.Admin && <Button primary content='Novo boleto' icon='add' style={{marginTop:ticketCount>0?5:0}} onClick={()=>this.newTicket(i)} /> }
				</Table.Cell>
				</Table.Row>
			);
			})}
		</Table.Body>
		</Table>
	);
	}

	advertiserSearch(e, { value }) {
	this.setState({ searchingAdvertiser: true, searchValue: value, searchResults: [] });

	setTimeout(() => {
		let match = new RegExp(value, 'i');

		let results = this.state.advertisers.filter((e) => {
		return match.test(e.name);
		});

		let resultsProcessed = [];
		for (let result of results) {
		resultsProcessed.push({ id: result.id, title: result.name });
		}

		this.setState({ searchingAdvertiser: false, searchResults: resultsProcessed });
	}, 200);
	}

	advertiserSelect(e, { result }) {
	this.setState({ searchValue: result.title, nfseEntity: result.id, boletoEntity: result.id });
	}

	handleNFSeInput(e, { name, value }) {
	if (name === 'nfseValue' || name === 'nfseCut') {
		this.setState({ [name]: parseInt(value.replace(/\.|,/g, ''), 10) / 100 }, () => {
		this.updateNFSeText();
		this.updateNFSeMinMax();
		});
		return;
	}

	this.setState({ [name]: value }, () => {
		if (name !== 'nfseText') {
		this.updateNFSeText();
		this.updateNFSeMinMax();
		}
	});
	}

	handleBoletoInput(e, { name, value }) {
	if (name === 'boletoValue') {
		this.setState({ [name]: parseInt(value.replace(/\.|,/g, ''), 10) / 100 });
		return;
	}
	if (name === 'boletoDescription') {
		this.setState({ [name]: value.slice(0,60) });
		return;
	}
	this.setState({ [name]: value });
	}

	async componentDidMount() {
	this.updateOrder();
	this.updateCheckingReport();

	if (this.props.userData.role === IDs.Admin) {
		try {
		const res = await window.cs_get('/entities', { type: 3, simple: 1 });
		this.setState({ advertisers: res.data });
		} catch (err) {
		console.error(err);
		}
	}
	}

	render() {
	const order = this.state.order;
	const advertiser = this.state.advertiser;
	const agency = this.state.agency;

	if (!order) {
		return (
		<Grid padded className='App-Order'>
			<ContentHeader userData={this.props.userData} path={['Pedido']}/>

			<Dimmer active={true} inverted>
			<Loader style={{marginTop:'35vh'}} size='large'>Carregando dados...</Loader>
			</Dimmer>
		</Grid>
		);
	}

	let cutsTotal = 0;
	let myNetworkCut = 0;
	const cutsInfo = this.getCutsInfo();

	let myRefs = [];
	for (let ref of this.props.userData.refs) myRefs.push(ref.id);

	for (let cut of cutsInfo) {
		cutsTotal += cut.agencyCut + cut.networksCut;
		if (this.props.userData.role === IDs.Exhibitor) {
		for (let networkCut of cut.networksCuts) {
			if (myRefs.indexOf(networkCut.network) !== -1)
			myNetworkCut += networkCut.cut;
		}
		}
	}

	let totalPaid = 0;
	for (let ticket of this.state.tickets) {
		if (ticket.status === 3) totalPaid += ticket.value;
	}

	for (let nfse of this.state.nfses) {
		if (nfse.no_cut === 1) totalPaid += nfse.networks_cut;
	}

	let nextPayments = 0;
	for (let installment of order.installments_details) {
		if (moment(installment.date).isAfter(moment().endOf('day'))) nextPayments += installment.price;
	}

	let hasMediaProduct = false;
	for (let item of order.items) {
		if (item.product === IDs.Preshow || item.product === IDs.Videowall || item.product === IDs.Bomboniere || item.product === IDs.Foyer) {
		hasMediaProduct = true;
		break;
		}
	}

	return (
		<Grid padded className='App-Order'>
		<ContentHeader userData={this.props.userData} path={['Pedido', `#${order.id}`]}/>

		{ order.id > 2005 && !this.state.order.signature && (this.props.userData.role === IDs.Advertiser || this.props.userData.role === IDs.Agency || this.props.userData.role === IDs.Admin) &&
			<Grid.Row style={{margin:'0 5px -10px 5px'}}>
			<Grid.Column>
				<Message warning icon>
				<Icon name='warning circle' />
				<Message.Content>
					<Message.Header>Atenção!</Message.Header>
					<p><span style={{cursor:'pointer',color:'#006a92'}} onClick={()=>this.setState({showTermsModal:true})}>Clique aqui</span> para validar o Termo de Adesão.</p>
				</Message.Content>
				</Message>
			</Grid.Column>
			</Grid.Row>
		}

		<Grid.Row className='content-margin'>
			<Grid.Column>
			<Segment className='info' secondary>
				<Grid columns={agency?5:4} stackable>
				<Grid.Row>
					<Grid.Column style={{ width: 'unset' }}>
					<Header>
						{advertiser.name}
						<Header.Subheader>
						<Icon name='id card outline' />
						<span>{advertiser.identity}</span>
						</Header.Subheader>
						<Header.Subheader>
						<Icon name='mail' />
						<a href={`mailto:${this.firstContact('email', advertiser.contacts)}`}>{this.firstContact('email', advertiser.contacts)}</a>
						</Header.Subheader>
						<Header.Subheader>
						<Icon name='phone' />
						<span>{this.firstContact('phone', advertiser.contacts)}</span>
						</Header.Subheader>
					</Header>
					</Grid.Column>

					{ agency &&
					<Grid.Column style={{ width: 'unset' }}>
						<Header>
						{agency.name}
						<Header.Subheader>
							<Icon name='id card outline' />
							<span>{agency.identity}</span>
						</Header.Subheader>
						<Header.Subheader>
							<Icon name='mail' />
							<a href={`mailto:${this.firstContact('email', agency.contacts)}`}>{this.firstContact('email', agency.contacts)}</a>
						</Header.Subheader>
						<Header.Subheader>
							<Icon name='phone' />
							<span>{this.firstContact('phone', agency.contacts)}</span>
						</Header.Subheader>
						</Header>
					</Grid.Column>
					}

					<Grid.Column style={{ width: 'unset', flex: 1 }} />

					<Grid.Column style={{ display: 'flex', alignItems: 'center', width: 'unset' }}>
					{this.props.userData.role === IDs.Admin &&
						<div>
						{order.auto_nfe !== 1 &&
							<Popup inverted position='top center' trigger={<Button onClick={()=>this.setAutoNfeActive(1)} color='grey' size='medium' icon='hand pointer outline' content='NF-e manual' />} style={{ textAlign: 'center' }}>
							Clique para <b>ativar</b> a emissão de notas fiscais automaticamente.
							</Popup>
						}

						{order.auto_nfe === 1 &&
							<Popup inverted position='top center' trigger={<Button onClick={()=>this.setAutoNfeActive(0)} color='olive' size='medium' icon='sync' content='NF-e automática' />} style={{ textAlign: 'center' }}>
							Clique para <b>desativar</b> a emissão de notas fiscais automaticamente.
							</Popup>
						}
						</div>
					}
					</Grid.Column>

					<Grid.Column style={{ width: 'unset' }}>
					<a href={`/pedidos/${order.id}/PI`} target='_blank' rel='noopener noreferrer'><Button labelPosition='left' style={{width:196}} primary size='small' icon='external' content='Termo de Adesão'/></a>
					<br />
					{ order.active === 1 && <Button disabled={this.props.userData.role!==IDs.Admin} onClick={()=>this.setOrderActive(0)} labelPosition='left' style={{width:196,marginTop:4}} negative size='small' icon='cancel' content='Cancelar T.A.'/> }
					{ order.active !== 1 && <Button disabled={this.props.userData.role!==IDs.Admin} onClick={()=>this.setOrderActive(1)} labelPosition='left' style={{width:196,marginTop:4}} color='olive' size='small' icon='check' content='Reativar T.A.'/> }
					</Grid.Column>
				</Grid.Row>
				</Grid>
			</Segment>
			</Grid.Column>
		</Grid.Row>

		{ this.props.userData.role === IDs.Admin &&
			<Grid.Row style={{padding:0}} className='content-margin'>
			<Grid.Column mobile={16} tablet={4} computer={4}>
				<Segment className='stat'>
				<Header color='olive'>
					{formatCurrency(order.total)}
					<Header.Subheader>TOTAL</Header.Subheader>
				</Header>
				</Segment>
			</Grid.Column>
			<Grid.Column mobile={16} tablet={4} computer={4}>
				<Segment className='stat'>
				<Header color='olive'>
					{formatCurrency(this.props.userData.role === IDs.Exhibitor ? myNetworkCut : order.total - cutsTotal)}
					<Header.Subheader>RECEBÍVEL</Header.Subheader>
				</Header>
				</Segment>
			</Grid.Column>
			<Grid.Column mobile={16} tablet={4} computer={4}>
				<Segment className='stat'>
				<Header color='olive'>
					{formatCurrency(totalPaid)}
					<Header.Subheader>PAGO</Header.Subheader>
				</Header>
				</Segment>
			</Grid.Column>
			<Grid.Column mobile={16} tablet={4} computer={4}>
				<Segment className='stat'>
				<Header color='olive'>
					{formatCurrency(nextPayments)}
					<Header.Subheader>VENC. FUTURO</Header.Subheader>
				</Header>
				</Segment>
			</Grid.Column>
			</Grid.Row>
		}

		<Grid.Row className='content-margin'>
			<Grid.Column>
			{ this.installmentsList() }
			</Grid.Column>
		</Grid.Row>

		{ hasMediaProduct && (this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid || this.props.userData.role === IDs.Advertiser || this.props.userData.role === IDs.Agency) &&
			[
			<TitledBlock key={0} title='gerenciador de arquivos'>
				<FileManager order={order} onMediaListUpdated={(list)=>this.refs.quotaEditor.setState({ medias: list })} userData={this.props.userData} />
			</TitledBlock>,

			<TitledBlock key={1} title='gerenciador de veiculação'>
				{ !order.active &&
				<Message warning>
					<Message.Header>Pedido inativo!</Message.Header>
					<p>Esse pedido se encontra inativo e ele <b>não será veiculado</b>.</p>
				</Message>
				}

				<OrderQuotaEditor ref='quotaEditor' order={order} programmingOrder={order} userData={this.props.userData} />
			</TitledBlock>
			]
		}

		{ this.state.checkingReport && this.state.checkingReport.length > 0 &&
			<TitledBlock title='Relatório de Exibição'>
			<ReactToPrint trigger={() => <Button icon='print' size='small' content='Imprimir' style={{float:'right',margin:'-4px 0 10px'}} />} content={() => this.table} />

			<div ref={el=>(this.table=el)}>
				<Table structured celled>
				<Table.Header>
					<Table.Row>
					<Table.HeaderCell>Período</Table.HeaderCell>
					<Table.HeaderCell>Cinema</Table.HeaderCell>
					<Table.HeaderCell>Sala</Table.HeaderCell>
					<Table.HeaderCell>Filme</Table.HeaderCell>
					<Table.HeaderCell>Horário</Table.HeaderCell>
					<Table.HeaderCell>Mídia</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{ this.state.checkingReport.map((e) => {
					if (e.status !== 1) return null;

					const startDate = moment(e.week);
					const endDate = moment(startDate).add(6, 'days');
					const movies = e.movies ? JSON.parse(e.movies) : [];

					const additionalData = e.additional_data ? JSON.parse(e.additional_data) : null;

					let movie = movies.length > 0 ? movies[0].split('|')[0] || 'Indisponível' : 'Indisponível';
					let hours = movies.length > 0 ? movies[0].split('|')[1] || 'Indisponível' : 'Indisponível';
					hours.split(',').join(' - ');

					return [
						<Table.Row key={e.id}>
						<Table.Cell rowSpan={movies.length}>{ startDate.format('DD/MM/YY') } a { endDate.format('DD/MM/YY') }</Table.Cell>
						<Table.Cell rowSpan={movies.length}>{ additionalData ? additionalData.shopping : null }<br/>{ e.theaterName }</Table.Cell>
						<Table.Cell rowSpan={movies.length}>{ e.room }</Table.Cell>
						<Table.Cell>{ movie }</Table.Cell>
						<Table.Cell>{ hours }</Table.Cell>
						<Table.Cell rowSpan={movies.length}>{ e.ref }</Table.Cell>
						</Table.Row>,
						...(movies.map((e, i) => {
						if (i === 0) return null;

						movie = e.split('|')[0] || 'Indisponível';
						hours = e.split('|')[1] || 'Indisponível';
						hours.split(',').join(' - ');

						return (
							<Table.Row key={`${e.id}_${i}`}>
							<Table.Cell>{ movie }</Table.Cell>
							<Table.Cell>{ hours }</Table.Cell>
							</Table.Row>
						)
						}))
					];
					})}
				</Table.Body>
				</Table>
			</div>
			</TitledBlock>
		}

		<Modal open={this.state.showTermsModal} size='small'>
			<Modal.Header>Aceite Online</Modal.Header>
			<Modal.Content>
			<p><a href={`/pedidos/${this.state.order.id}/PI`} target='_blank' rel='noopener noreferrer'>Visualize</a> o Termo de Adesão <b>#{this.state.order.id}</b>. Após ler atentamente, declare e aceite os termos contratados.</p>
			{/*<p>Caso deseje enviar o contrato assinado por e-mail, ignore a assinatura digital e marque a opção correspondente.</p>
			<Checkbox checked={this.state.handSignature} label='Efetuarei o envio do contrato assinado por e-mail' onChange={(e, {checked})=>this.setState({handSignature:checked})} />
			{ !this.state.handSignature && <SignaturePad ref="signature"/> */}
			<Checkbox style={{marginTop:4}} checked={this.state.termsAccepted} label='Declaro que li e aceito os termos do contrato' onChange={(e, {checked})=>this.setState({termsAccepted:checked})} />
			</Modal.Content>
			<Modal.Actions>
			{/*<Button style={{float:'left'}} size='small' content='Limpar assinatura' onClick={()=>this.refs.signature.clear()}/>*/}
			<Button size='small' negative content='Cancelar' onClick={()=>this.setState({showTermsModal:false})} />
			<Button disabled={!this.state.termsAccepted} style={{marginLeft:0}} size='small' primary labelPosition='right' icon='check' content='Concluir' onClick={this.sendSignature.bind(this)} />
			</Modal.Actions>
		</Modal>

		<Modal open={this.state.showNFSeModal} size='tiny'>
			<Modal.Header>Nova NFS-e</Modal.Header>
			<Modal.Content>
			<Form loading={this.state.nfseLoading} error={!!this.state.nfseCutError}>
				<div className='wide field'>
				<label>Tomador dos Serviços</label>
				<Search style={{width:'100%',margin:0}} fluid value={this.state.searchValue} showNoResults={!this.state.searchingAdvertiser} results={this.state.searchResults} onSearchChange={this.advertiserSearch.bind(this)} onResultSelect={this.advertiserSelect.bind(this)} loading={this.state.searchingAdvertiser} minCharacters={3} noResultsMessage='Nenhum anunciante encontrado' input={{placeholder:'Pesquisar anunciante...'}} />
				</div>
				<Form.Group>
				<Form.Input autoComplete='off' width='8' name='nfseValue' label='Valor Líquido' value={formatNumber(this.state.nfseValue, 2)} onChange={this.handleNFSeInput.bind(this)} />
				<Form.Input autoComplete='off' error={!!this.state.nfseCutError} width='8' name='nfseCut' label='Repasse Cinema' value={formatNumber(this.state.nfseCut, 2)} onChange={this.handleNFSeInput.bind(this)} />
				</Form.Group>
				<Message error content={this.state.nfseCutError} />
				<Form.Checkbox label='Nota sem repasse' checked={this.state.nfseNoCut} onChange={(e, {checked})=>this.setState({ nfseNoCut: checked }, () => this.updateNFSeText())} />
				<Form.TextArea label='Discriminação dos Serviços' name='nfseText' rows={15} style={{fontSize:11}} value={this.state.nfseText} onChange={this.handleNFSeInput.bind(this)} />
				<Form.Dropdown label='Item da Lista de Serviços' name='nfseType' selection options={nfseTypes} value={this.state.nfseType} onChange={this.handleNFSeInput.bind(this)} />
			</Form>
			</Modal.Content>
			<Modal.Actions>
			<Button disabled={this.state.nfseLoading} size='small' negative content='Cancelar' onClick={()=>this.setState({ showNFSeModal: false })} />
			<Button disabled={this.state.nfseLoading} size='small' primary labelPosition='right' icon='check' content='Salvar' onClick={this.addNFSe.bind(this)}/>
			</Modal.Actions>
		</Modal>

		<Modal open={this.state.showBoletoModal} size='tiny'>
			<Modal.Header>Novo boleto</Modal.Header>
			<Modal.Content>
			<Form loading={this.state.boletoLoading}>
				<div className='wide field'>
				<label>Sacado</label>
				<Search style={{width:'100%',margin:0}} fluid value={this.state.searchValue} showNoResults={!this.state.searchingAdvertiser} results={this.state.searchResults} onSearchChange={this.advertiserSearch.bind(this)} onResultSelect={this.advertiserSelect.bind(this)} loading={this.state.searchingAdvertiser} minCharacters={3} noResultsMessage='Nenhum anunciante encontrado' input={{placeholder:'Pesquisar anunciante...'}} />
				</div>
				<Form.Group>
				<Form.Input autoComplete='off' width='8' name='boletoValue' label='Valor do documento' value={formatNumber(this.state.boletoValue, 2)} onChange={this.handleBoletoInput.bind(this)} />
				<div className='eight wide field'>
					<label>Vencimento</label>
					<DayPickerInput onDayChange={(date)=>this.setState({ boletoDate: date })} value={moment(this.state.boletoDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: [{ before: moment().toDate() }, { daysOfWeek: [0, 6] }] }} />
				</div>
				</Form.Group>
				<Form.Input autoComplete='off' name='boletoDescription' label='Descrição' value={this.state.boletoDescription} onChange={this.handleBoletoInput.bind(this)} />
			</Form>
			</Modal.Content>
			<Modal.Actions>
			<Button disabled={this.state.boletoLoading} size='small' negative content='Cancelar' onClick={()=>this.setState({ showBoletoModal: false })} />
			<Button disabled={this.state.boletoLoading} size='small' primary labelPosition='right' icon='check' content='Salvar' onClick={this.addBoleto.bind(this)}/>
			</Modal.Actions>
		</Modal>

		<Modal size={'tiny'} open={!!this.state.nfseRemove}>
			<Modal.Header>
			Removendo NFS-e
			</Modal.Header>
			<Modal.Content>
			Você tem certeza que deseja cancelar e remover essa NFS-e?
			</Modal.Content>
			<Modal.Actions>
			<Button negative content='Não' onClick={()=>this.setState({nfseRemove:undefined})} />
			<Button positive icon='checkmark' labelPosition='right' content='Sim' onClick={this.removeNFSe.bind(this)}/>
			</Modal.Actions>
		</Modal>

		<Modal size={'tiny'} open={!!this.state.boletoRemove}>
			<Modal.Header>
			Removendo boleto
			</Modal.Header>
			<Modal.Content>
			Você tem certeza que deseja remover esse boleto?
			</Modal.Content>
			<Modal.Actions>
			<Button negative content='Não' onClick={()=>this.setState({boletoRemove:undefined})} />
			<Button positive icon='checkmark' labelPosition='right' content='Sim' onClick={this.removeBoleto.bind(this)}/>
			</Modal.Actions>
		</Modal>
		</Grid>
	);
	}
}

export default withRouter(Order);
